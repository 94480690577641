import React from 'react'
import '../App.css'
import { CCarousel, CCarouselItem, CImage } from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'
import client1 from '../images/AIRD.png'
import client2 from '../images/bayport.png'
import client3 from '../images/knight.png'
import client4 from '../images/Buildnet.png'
import client5 from '../images/cidi.png'

function Slider() {
  return (
    <div className='slider-container'>
        <CCarousel controls>
            <CCarouselItem>
                <CImage 
                    src={client1} 
                    alt='client1'
                    className='aird1'>
                </CImage>
            </CCarouselItem>
            <CCarouselItem>
                <CImage 
                    src={client2}
                    alt='client2'
                    className='bayport1'>
                </CImage>
            </CCarouselItem>
            <CCarouselItem>
                <CImage 
                    src={client3}
                    alt='client3'
                    className='knight1'>
                </CImage>
            </CCarouselItem>
            <CCarouselItem>
                <CImage 
                    src={client4}
                    alt='client4'
                    className='buildnet1'>
                </CImage>
            </CCarouselItem>
            <CCarouselItem>
                <CImage 
                    src={client5}
                    alt='client5'
                    className='cidi'>
                </CImage>
            </CCarouselItem>
        </CCarousel>
    </div>
  )
}

export default Slider
