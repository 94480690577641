import React from 'react'
import '../App.css'
import Footer from '../components/footer'
import Designer from '../components/designer'
import Report from '../components/contactForm'
import Direction from '../components/direction'
import Navig from '../components/navig'

function Contact() {
  return (
    <>
    <Navig />
    <div className='contact-container'>
      <h1 className='contact-title'>Let's talk</h1>
      <div className='contact-txt'>
        <p className='contact-paragraph'>Tell us all about your project right here, or send us an email at 
        <span className='contact-email'>info@irecktech.com</span>
        </p>
      </div>
      <Report />
      <br></br>
      <br></br>
      <Direction />
    </div>
    <div>

    </div>
    <Footer />
    <Designer />
    </>
  )
}

export default Contact
