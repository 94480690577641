import React from 'react'
import '../App.css'
import Footer from '../components/footer'
import Designer from '../components/designer'

import AIRD from '../images/AIRD.png'
import absa from '../images/absa.png'
import bayport from '../images/bayport.png'
import arcadia from '../images/arcadia.png'
import buildnet from '../images/Buildnet.png'
import buwembo from '../images/buwembo.png'
import cidi from '../images/cidi.png'
import crystal from '../images/crystal.png'
import efc from '../images/efc.png'
import finance from '../images/finance trust.png'
import knight from '../images/knight.png'
import kk from '../images/kk.png'
import kaffiika from '../images/kaffiika.png'
import kta from '../images/kta.png'
import sga from '../images/sga.png'
import marie from '../images/marie.png'
import koudijs from '../images/koudijs.png'
import utb from '../images/utb.png'
import uhmg from '../images/uhmg.png'
import spedag from '../images/spedag.png'
import Navig from '../components/navig'



function Work() {
  return (
    <>
    <Navig />
    <div>
      <h1 className='works-heading'>Our Happy Clients</h1>
      <div className='clients-logo'>
          <img
            src={AIRD}
            alt='aird'
            className='aird'
          />
          <img
            src={arcadia}
            alt='arcadia'
            className='arcadia'
          />
          <img
            src={absa}
            alt='absa'
            className='absa'
          />
          <img
            src={bayport}
            alt='bayport'
            className='bayport'
          />
          <img
            src={buildnet}
            alt='buildnet'
            className='buildnet'
          />
          <img
            src={buwembo}
            alt='buwembo'
            className='buwembo'
          />
          <img
            src={crystal}
            alt='crystal'
            className='crystal'
          />
          <img
            src={cidi}
            alt='cidi'
            className='cidi'
          />
          <img
            src={efc}
            alt='efc'
            className='efc'
          />
          <img
            src={finance}
            alt='finance'
            className='finance'
          />
          <img
            src={kaffiika}
            alt='kaffiika'
            className='kaffiika'
          />
          <img
            src={kk}
            alt='kk'
            className='kk'
          />
          <img
            src={knight}
            alt='knight'
            className='knight'
          />
          <img
            src={koudijs}
            alt='kou'
            className='koudijs'
          />
          <img
            src={kta}
            alt='kta'
            className='kta'
          />
          <img
            src={marie}
            alt='marie'
            className='marie'
          />
          <img
            src={uhmg}
            alt='uhmg'
            className='uhmg'
          />
          <img
            src={utb}
            alt='utb'
            className='utb'
          />
          <img
            src={sga}
            alt='sga'
            className='sga'
          />
          <img
            src={spedag}
            alt='spedag'
            className='spedag'
          />
      </div>
    </div>
    <Footer />
    <Designer/>
    </>
  )
}

export default Work
