import React from 'react'
import '../App.css'
import Footer from '../components/footer'
import Designer from '../components/designer'
import Bg_service from '../images/service.jpg'
import Access from '../images/access.jpg'
import cctv from '../images/cctv.jpg'
import alarms from '../images/alarms.jpg'
import Network from '../images/network.jpg'
import Phone from '../images/phone.jpg'
import Navig from '../components/navig'

function Service() {
  return (
    <>
    <Navig/>
      <img src={Bg_service}
        alt='Bg_service'
        width={'100%'}
        height={200}
        className='Bg_service'
      />
      <div className='access-container'>
        <div className='access-content'>
          <h4>Access Control and Time &
            Attendance Management
            Systems
          </h4>
          <p>Access control systems are advanced
            and highly useful devices that
            allow selective access to a particular
            area. These systems put the owner in a
            position of allowing or denying access
            to any person at any given time, manage
            access to resources, track and deter
            access.
            <br></br>
            <br></br>
            Time and attendance systems are
            designed to automate employee time
            and attendance, allowing you to collect
            and organize employee time data simply
            and accurately.
            <br></br>
            <br></br>
            For both access control and time &
            attendance systems, several techniques
            are provided for verifying user identity
            such as password, RFID or Mifare card,
            fingerprint and facial identification.
          </p>
        </div>
          <img src={Access}
            alt='access'
            width={500}
            height={500}
            className='access-img'
          />
      </div>
      <div className='cctv-container'>
        <img src={cctv}
          alt='cctv'
          width={546}
          height={346}
          className='cctv-image'
        />
        <div className='cctv-content'>
          <h4>CCTV Surveillance
            Systems
          </h4>
          <p>
            These are one of the most reliable
            resources for electronic surveillance,
            providing “24/7 eyes on” and a
            recording feature which facilitates
            users to record daily events and keep
            track. 
            <br></br>
            <br></br>
            CCTV footage can be viewed
            (real time and playback) and stored
            remotely using video management
            software and applications.
          </p>
        </div>
      </div>
      <div className='alarm-container'>
        <div className='alarm-content'>
            <h4>Intruder Alarm systems</h4>
            <p>
              Intrusion alarm systems are designed to
              detect unauthorized entry into premises,
              preventing theft, property damage and harm
              to occupants. These consist of an array of
              sensors, control panels and alerting systems.
              <br></br>
              <br></br>
              Photoelectric beam systems can also be
              integrated to the intrusion alarm systems,
              to enhance security. In order to improve the
              detection surface area, the beams are often
              employed in stacks of two or more.
            </p>
        </div>
          <img src={alarms}
              alt='alarm'
              width={546}
              height={346}
              className='alarm-img'
            />
      </div>
      <div className='phone-container'>
            <img src={Phone}
              alt='phone'
              width={546}
              height={346}
              className='phone-img'
            />
        <div className= 'phone-content'>
            <h4>PABX Systems Integration</h4>
            <br></br>
            <p>
            Modern office settings need to have voice
            and telephone access systems by the
            desk. Office communication is therefore not only
            made easier and better, but as well productivity
            is enhanced with the use of PABX systems.
            </p>
        </div>
      </div>
      <div className='net-container'>
        <div className='net-content'>
          <h4>
            Network Infrastructural
            Design and Implementation
          </h4>
          <br></br>
          <p>
            With extensive experience and
            expertise, we are able to design,
            model and install networks, provide
            support, troubleshooting and short or long
            term maintenance for networks.
          </p>
        </div>
        <img src={Network}
              alt='network'
              width={546}
              height={346}
              className='net-img'
            />
      </div>
    <Footer />
    <Designer />
    </>
  )
}

export default Service
