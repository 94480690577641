import React from 'react'

function Designer() {
  return (
    <div className='designer-container'>
      <p>© Ireck Technologies Ltd. All Rights Reserved</p>
    </div>
  )
}

export default Designer
