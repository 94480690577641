import React from 'react'
import '../App.css'
import finger from '../images/finger.jpg'
import alarm from '../images/alarm.jpg'
import camera from '../images/camera.jpg'
import { Link } from 'react-router-dom'

function Works() {
  return (
    <>
    <div className='products-container'>
      <h1 className='products-title'>Products And Services.
        <br></br>
        <span><h4 className='products-subtitle'>You Want It We Create It.</h4></span>
      </h1>
      <div className='products-list'>
        <div className='products-images'>
            <img
                src={finger}
                alt='finger'
            />
            <div className='products-content'>
                <h5>
                    Attendance Management
                    
                </h5>
            </div>
        </div>
        <div className='products-images'>
            <img
                src={camera}
                alt='camera'
            />
            <div className='products-content'>
                <h5>CCTV Surveillance
                    Systems
                </h5>
            </div>
        </div>
        <div className='products-images'>
            <img
                src={alarm}
                alt='alarm'
            />
            <div className='products-content'>
                <h5>
                Intruder Alarm systems
                </h5>
            </div>
        </div>
      </div>
      <div className='products-btn'>
        <li><Link to='/service' className='links-footer'>Products & Services</Link></li>
    </div>
    </div>
    </>
  )
}

export default Works
