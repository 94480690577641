import React from 'react'
import '../App.css'
import Navig from '../components/navig'
import Footer from '../components/footer'
import Designer from '../components/designer'
import about from '../images/about.png'
import bg_about from '../images/bg-about.jpg'

function About() {
  return (
    <>
    <Navig/>
    <div className='about-container'>
      <div>
        <img
          className='bg-about'
          src={bg_about}
          alt='bg_about'
        />
      </div>
      <div className='about-content'>
        <img
          className='about-title'
          src={about}
          alt='about'
        />
        <p className='about-paragraph'>
        Since its inception in 2014, Ireck Technologies
        Limited has quickly evolved to become a
        proven Electronic Security and IT service provider.
        </p>
        <p>
          Our aim is to consistently provide our customers
          with the cutting-edge technology, personalized
          service and customer support as expected. We
          currently serve a number of large recognized
          entities, as well as SMEs and Individuals.
        </p>
        <p>
        Our commitment is the seamless execution of
        innovative, state of the art technological systems,
        delivered within budget and agreed timelines.
        Our approach involves a proactive theory of wellgroomed and an exceptionally trained technical
        team. Our strategy entails several steps to mitigate
        the possibility and opportunity for property theft,
        workplace abuse and endangerment of persons
        within the facility, as regards to security.
        </p>
        <div className='goals-container'>
          <div className='mission-container'>
            <h3>Mission</h3>
            <p>To make technology an asset, by delivering
              the best and most reliable technological
              solutions to our clients.
            </p>
          </div>
          <div>
            <h3>Vision</h3>
            <p>To be the leading technological
              firm in customer value
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <Designer />
    </>
  )
}

export default About
