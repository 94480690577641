import React, {useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home'
import About from './pages/about'
import Work from './pages/work'
import Contact from './pages/contact'
import Service from './pages/services'
import AOS from 'aos'
import 'aos/dist/aos.css'



function App() {

  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='About' element={<About />}/>
          <Route path='Work' element={<Work />}/>
          <Route path='Service' element={<Service />}/>
          <Route path='Contact' element={<Contact />}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
