import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import '../App.css'
import home from '../pages/contact'

function Report() {
    const [validated, setValidated] = useState(false)

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if(form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        }
        setValidated(true)
    }

  return (
    <Form className='contactForm' noValidate validated={validated} onSubmit={handleSubmit} action='/success' method='post'>
        <Form.Group>
            <Form.Label className='contact-label'>Name & Company</Form.Label>
            <Form.Control type='text' placeholder='Company Or Name' size='lg' required></Form.Control>
        </Form.Group>
        <br></br>
        <Form.Group>
            <Form.Label className='contact-label' >Email</Form.Label>
            <Form.Control type='email' placeholder='name@email.com' size='lg' required></Form.Control>
        </Form.Group>
        <br></br>
        <Form.Group>
            <Form.Label className='contact-label' >Project Description</Form.Label>
            <Form.Control as='textarea' placeholder='Whatever you want us to do for you' rows={4} required></Form.Control>
        </Form.Group>
        <br></br>
        <div className="d-grid gap-2">
            <Button type='submit' size="lg" className='contact-btn'>
                Send
            </Button>
        </div>
    </Form>
  )
}

export default Report
