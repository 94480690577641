import React, { useState } from 'react'
import './navig.css'
import '../App.css'
import { NavLink } from 'react-router-dom'
import logo from '../images/logo2.png'

import DensitySmall from '@mui/icons-material/DensitySmall'
import ArrowForward from '@mui/icons-material/ArrowForward'


function Navig() { 
    const [clicked, setClicked] = useState(false)

    const handleClicked = () => {
        setClicked(!clicked)
    }

  return (
    <>
      <nav className='navbar'>
        <div className="nav-container">
            <img className="web-logo" src={logo} alt='logo' width={200} height={100}></img>
          <ul className={clicked ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClicked}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClicked}
              >
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/Work"
                activeClassName="active"
                className="nav-links"
                onClick={handleClicked}
              >
                Works
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/Service"
                activeClassName="active"
                className="nav-links"
                onClick={handleClicked}
              >
                Products & Sevices
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClicked}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClicked}>
            {clicked ? (
              <span className="icon">
                <ArrowForward />{" "}
              </span>
            ) : (
              <span className="icon">
                <DensitySmall />
              </span>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navig
