import React from 'react'
import '../App.css'
import Slider from '../components/slider'
import Navig from '../components/navig'
import Works from '../components/works'
import Footer from '../components/footer'
import Designer from '../components/designer'
import { Link } from 'react-router-dom'
import 'aos/dist/aos.css'


function Home() {

  return (
    <>
    < Navig/>
    <section className='welcome'>
      <div className='welcome-container'>
        <h1 className='welcome-text'>
          Creating New Solutions Based on Innovative Ideas.
          <br></br>
          <span>
            We Guarantee Delivery of the Best Solutions.
            </span>
          </h1>
        <div className='welcome-btn'>
          <li><Link to='/contact' className='welcome-btn'>Get In Touch</Link></li>
        </div>
        <div className='clients-container'>
          <div className='clients-message'>
            <h2 className='clients-heading'>Our Clients</h2>
            <p>We thank you for the opportunity you gave us to serve you with on demand services. You all made our existance meaningfull.</p>
          </div>
          <div>
            <Slider />
          </div>
        </div>
      </div>
    </section>
    <section>
      <Works />
    </section>
    <section>
      <Footer />
    </section>
    <Designer/>
    </>
  )
}

export default Home
