import React from 'react'
import logo from '../images/logo2.png'
import { Link, Outlet } from 'react-router-dom'

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-logo'>
        <img 
            src={logo}
            alt='logo'
            width={200}
            height={100}
        />
      </div>
      <div className='footer-links'>
        <h5>Links</h5>
            <ul className='foot-links'>
                <li><Link to='/' className='links-footer'>Home</Link></li>
                <li><Link to='/about' className='links-footer'>About Us</Link></li>
                <li><Link to='/Work' className='links-footer'>Work</Link></li>
                <li><Link to='/service' className='links-footer'>Products & Services</Link></li>
                <li><Link to='/contact' className='links-footer'>Contact</Link></li>
            </ul>
            <Outlet />
      </div>
      <div className='footer-contact'>
        <h5>Contact</h5>
        <p>info@irecktech.com</p>
        <p>0392 915 492</p>
        <p> 0414 581 873</p>
      </div>
      <div className='footer-address'>
        <h5>Address</h5>
        <p>Plot 606, Kisosonkole, Kyambogo</p>
        <p>P.O.Box 7021, Kampala, Uganda.</p>
      </div>
    </div>
  )
}

export default Footer
