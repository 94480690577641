import { MailOutlineOutlined, MapOutlined, RingVolumeOutlined } from '@mui/icons-material'
import React from 'react'

function Direction() {
  return (
    <div className='direction-container'>
        <div className='ring'>
            <RingVolumeOutlined fontSize='large' className='icon'></RingVolumeOutlined>
            <h5>Telephone</h5>
            <p>0414 581 873</p>
            <p>0392 915 492</p>
        </div>
        <div className='ring'>
            <MapOutlined sx={{ fontSize: 40 }} className='icon'></MapOutlined>
            <h5>Address</h5>
            <p>Plot 606 Kisosonkole,<br></br> Kyambogo</p>
            <p>P.O.Box 7021 Kampala,<br></br> Uganda.</p>
        </div>
        <div className='ring'>
            <MailOutlineOutlined fontSize='large' className='icon'></MailOutlineOutlined>
            <h5>Email</h5>
            <p>info@irecktech.com</p>
        </div>
    </div>
  )
}

export default Direction
